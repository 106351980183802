.fp {
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.fpItem{
  flex: 1;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.fpImg{
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.fpName{
  color: #333;
  font-weight: bold;
}

.fpCity{
  font-weight: 300;
}

.fpPrice{
  font-weight: 500;
}

.fpRating>button{
  background-color: #56bbad;
  color: white;
  border: none;
  padding: 3px;
  margin-right: 10px;
  font-weight: bold;
}

.fpRating>span{
  font-size: 14px;
}


.fpRefund{
  float: right;
  margin-top: -30px;
}
.fpRefund>button{
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  margin-right: 10px;
  font-weight: bold;
}

.fpRefund>span{
  font-size: 14px;
}

.fpNonRefund{
  float: right;
  margin-top: -30px;
}
.fpNonRefund>button{
  background-color: gray;
  color: white;
  border: none;
  padding: 5px;
  margin-right: 10px;
  font-weight: bold;
  cursor: not-allowed;
}

.fpNonRefund>span{
  font-size: 14px;
}

.centerSpinner {
  align-content: center;
  align-items: center;
  padding-left: 40%;
}