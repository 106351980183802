

.hotelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.hotelWrapper {
  width: 100%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.bookNow {
  position: absolute;
  top: 10px;
  right: 0;
  border: none;
  padding: 10px 20px;
  background-color: #008009;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.hotelTitle {
  font-size: 24px;
}

.hotelTitle2 {
  font-size: 18px;
}

.hotelAddress {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hotelDistance {
  color: #56bbad;
  font-weight: 500;
}

.hotelPriceHighlight {
  color: #008009;
  font-weight: 500;
}

.hotelImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.hotelImgWrapper {
  width: 33%;
}

.hotelImg {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.hotelDetails {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.hotelDetailsTexts {
  flex: 3;
}

.hotelDesc {
  font-size: 14px;
  margin-top: 20px;
}

.hotelDetailsPrice {
  flex: 1;
  background-color: #ebf3ff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hotelDetailsPrice > h1 {
  font-size: 18px;
  color: #555;
}
.hotelDetailsPrice > span {
  font-size: 14px;
}
.hotelDetailsPrice > h2 {
  font-weight: 300;
}
.hotelDetailsPrice > button {
  border: none;
  padding: 10px 20px;
  background-color: #56bbad;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.slider{
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.613);
  z-index: 999;
  display: flex;
  align-items: center;
}

.sliderWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderImg{
  width: 80%;
  height: 80vh;
}

.close{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: lightgray;
  cursor: pointer;
}

.arrow{
  margin: 20px;
  font-size: 50px;
  color: lightgray;
  cursor: pointer;
}

/* .searchItem {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  background-color: #008009;
} */




.table-bordered {
  border: 1px solid #dee2e6;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
table {
  border-collapse: collapse;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}
.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

td {
  display: table-cell;
  vertical-align: inherit;
}

.ow_room_booking_mtr {
  margin-top: 1rem;
}